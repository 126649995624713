<script>
    import { required } from "vuelidate/lib/validators";
    import Customers from '@/services/Customers';
    import Business from "@/services/Business";
    import validationMessages from '@/components/validations'
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";

    export default {
        components:{
            validationMessages,
            vueDropzone:vue2Dropzone
        },
        props: {
            businessMemberData: {
                type: Object,
                default: ()=>({
                    id:'',
                    first_name : '',
                    last_name : '',
                    email : '',
                    company_role : '',
                    phone : '',
                    linkedin_url : '',
                    profile_url : '',
                }),
            },
        },
        watch: {
            businessMemberData: {
                immediate: true,
                deep: true,
                handler(newVal) {
                    if (newVal) {
                        this.id = newVal.customer_id;
                        this.first_name = newVal.name;
                        this.last_name = newVal.surname;
                        this.email = newVal.email;
                        this.company_role = newVal.company_role;
                        this.phone = newVal.phone_number;
                        this.linkedin_url = newVal.linkedin_url;
                        this.profile_url = newVal.profile_url || '';
                        this.photoDeleted = false;
                    }
                },
            }
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),

                submitted: false,
                showModal: false,
                tryingToEdit: false,
                photoDeleted:false,
                dropzoneOptions_profile_url: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 200,
                    thumbnailMethod: 'contain',
                    maxFilesize: 5,
                    acceptedFiles: 'image/*',
                    maxFiles: 1,
                },
            };
        },
        validations: {
            first_name: {
                required,
            },
            last_name: {
                required,
            },
        },
        methods: {
            selectFileHome(files) {
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_profile_url.getQueuedFiles();

                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only select one image');

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_profile_url.removeFile(value);
                        });
                    }
                    this.profile_url = queuedFiles[0];
                    this.photoDeleted = false;
                }, 250);
            } ,
            deleteImage() {
                this.photoDeleted = true;
                this.profile_url = '';
            },
            async editCustomer(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        const data ={
                            name: this.first_name,
                            surname: this.last_name,
                            email:this.email,
                            phone_number:this.phone,
                            profile_url:this.profile_url,
                            company_role:this.company_role,
                            linkedin_url:this.linkedin_url,
                            csrf_token: this.csrf_token,
                            _method:'PATCH'
                        }
                        await Business.editBusinessMember(this.id,data).then((response) => {
                            this.successmsg("Change successfully saved");
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg("Failed to save changes")
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            resetProps(){
                this.submitted = false
            }
        },

    };
</script>

<template>
    <b-modal @hidden="resetProps" v-model="showModal" id="business_member_edit" title="Edit Business User" title-class="font-18" centered>
        <form @submit.prevent="editCustomer">

            <b-form-group label="First Name" label-for="formrow-first_name-input" class="mb-3">
                <b-form-input
                        v-model.trim="first_name"
                        type="text"
                        id="first_name"
                        aria-describedby="first_name-feedback"
                        :class="{
                      'is-invalid': submitted && $v.first_name.$error,
                    }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'First Name'" :validationName="$v.first_name"></validationMessages>
            </b-form-group>
            <b-form-group label="Surname" label-for="formrow-last_name-input" class="mb-3">
                <b-form-input
                        v-model.trim="last_name"
                        type="text"
                        id="last_name"
                        aria-describedby="last_name-feedback"
                        :class="{
                      'is-invalid': submitted && $v.last_name.$error,
                    }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Last Name'" :validationName="$v.last_name"></validationMessages>
            </b-form-group>
            <b-form-group label="Email" label-for="formrow-email-input" class="mb-3">
                <b-form-input
                        v-model.trim="email"
                        type="text"
                        id="email"
                        aria-describedby="email-feedback"

                >
                </b-form-input>
            </b-form-group>
            <b-form-group label="Phone" label-for="formrow-phone-input" class="mb-3">
                <b-form-input
                        v-model.trim="phone"
                        type="text"
                        id="email"
                        aria-describedby="phone-feedback"
                >
                </b-form-input>
            </b-form-group>
            <b-form-group label="Company Role" label-for="formrow-company_role-input" class="mb-3">
                <b-form-input
                        v-model.trim="company_role"
                        type="text"
                        id="email"
                        aria-describedby="company_role-feedback"
                >
                </b-form-input>
            </b-form-group>
            <b-form-group label="Linked In URL" label-for="formrow-linkedin_url-input" class="mb-3">
                <b-form-input
                        v-model.trim="linkedin_url"
                        type="text"
                        id="email"
                        aria-describedby="linkedin_url-feedback"

                >
                </b-form-input>
            </b-form-group>
            <b-form-group label="Profile Image" label-for="formrow-profile_url-input" class="mb-3">
                <template v-if="!photoDeleted && profile_url">
                    <b-form-group label-for="image" class="mb-3 text-center image-area">
                        <img :src="profile_url" alt="" width="200" class="img-thumbnail" />
                    </b-form-group>
                    <div class="text-center mt-3">
                        <a class="btn text-danger" v-b-tooltip.hover title="Delete Image" @click="deleteImage">
                            <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                    </div>
                </template>
                <template v-else>
                    <vue-dropzone
                            id="dropzone_prfile_url"
                            ref="myVueDropzone_profile_url"
                            :use-custom-slot="true"
                            :options="dropzoneOptions_profile_url"
                            @vdropzone-files-added="selectFileHome"
                            no-progress-bar
                    >
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop file here or click to upload.</h4>
                        </div>
                    </vue-dropzone>
                </template>
            </b-form-group>



            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editCustomer" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Change</b-button>
        </template>
    </b-modal>
</template>